import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Iframe from "react-iframe"

import Layout from "../../../components/common/layout/layout"

import travel from "../../../images/icons/Icon/Travel.png"
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image'


const query = graphql`
  query {
    allStrapiStore {
      edges {
        node {
          storeName
          storeLink
          storeDescription
          storeCategory
          storeImage {
            childImageSharp {
              fixed(width: 320, height: 240) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`;

const DTravel = () => (
  <Layout>
    <CategoryContainer>
        <Icon src={travel}></Icon>
        <CategoryHeader>Exotic travel experiences</CategoryHeader>
        <CategoryText>
            Want to take that Mediterranean cruise or go island hopping in the Caribbean? Now is the time to fulfil this dream. Take a dili loan and snooze all the way on your flight to the dream destination. No more postponing, no more settling for cheaper alternatives.
            <br/>
            <br/>
            Pay for all your travel deals with dili loans:
            <ul>
                <li>Bespoke holidays</li>
                <li>World events related curated travel – e.g. Olympics, World Cup, IPL, Formula 1, etc</li>
                <li>Safaris and Cruises</li>
                <li>Religious circuits</li>
            </ul>
        </CategoryText>
    
    <Spacing />
    <Stores>
        <StaticQuery
            query={query}
            render={data => (
                <StoreC>
                    {data.allStrapiStore.edges.filter(store => store.node.storeCategory === "travel").map(store => (
                        <Store>
                            <StoreImg fixed={store.node.storeImage.childImageSharp.fixed}/>
                            <StoreHeader>{store.node.storeName}</StoreHeader>
                            <StoreText>{store.node.storeDescription}</StoreText>
                            <Link to={store.node.storeLink}>
                                <StoreButton>Shop now</StoreButton>
                            </Link>
                        </Store>
                    ))}
                </StoreC>
            )}
        />
    </Stores>
    <Spacing />
    <FormText>
    <FormHeader>
        Don’t see what you want to buy here? Help us curate a loan option for you.
    </FormHeader>
    <FormSubheader>
        At dili, we are constantly looking to serve our customers better. If your requirement does not fit in the categories listed above, or the merchant you wish to buy from is not currently signed up with dili, tell us about it and we will try to curate a loan option for you. 
    </FormSubheader>
    </FormText>
    <FormContainer>
        <FormEmbed src="https://docs.google.com/forms/d/e/1FAIpQLScbyaRY2G20xafoCvqNfw_37ZlsT9-bR63qV-LoW__eiKKkig/viewform?embedded=true" width="640" height="640" frameBorder="0">
            Loading...
        </FormEmbed>
    </FormContainer>
    </CategoryContainer>
  </Layout>
)

export default DTravel


export const Spacing = styled.div`
    margin-bottom: 120px;
`

export const CategoryContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 160px;
`

export const CategoryHeader = styled.div`
    color: #142630;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-right: 40px;
`

export const CategoryText = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-top: 20px;
    width: 70%;
`

export const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin-bottom: 32px;
`

export const Stores = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const Store = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    margin-bottom: 80px;
`

export const StoreImg = styled(Img)`
    margin-bottom: 16px;
    border-radius: 20px;
`

export const StoreHeader = styled.div`
    font-size: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    width: 320px;
`

export const StoreText = styled.div`
    font-size: 18px;
    color: grey;
    margin-bottom: 16px;
    width: 320px;
    line-height: 1.3;
`

export const StoreButton = styled.button`
    background: black;
    color: white;
    font-weight: bold;
    font-size: 14px;
    width: 96px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 40px;
`

const FormText = styled.div`
    flex-direction: column;
    margin-top: 120px;
`

const FormHeader = styled.div`
    font-size: 36px;
    font-weight: bold;
    color: #142630;
    width: 60%;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 40px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        width: 80%;
    }
`

const FormSubheader = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    width: 60%;
    font-size: 20px;
    color: #465F6D;
    line-height: 1.5;

    @media (max-width: ${props => props.theme.screen.md}) {

    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 480px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 360px;
        font-size: 14px;
    }
`

const FormContainer = styled.div`
    display: flex;
    padding-top: 24px;
    padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
    width: 640px;
    height: 640px;
`

export const StoreC = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`